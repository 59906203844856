import React from 'react'
import Layout from '../Layout';
import Divider from '../Divider';
import Img from 'gatsby-image';
import { Carousel } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from "gatsby";
import './project.css';

const variants = {}

const container = {
    initial: {
        opacity: 1,
    },
    enter: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
        },
    },
    pageWorkAnimate:{
        
    }
};

const animatedItem = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition:{
      opacity:{ duration: 1 }
    }
  },
};

const variantsPortfolio = {
  initial:{
    x:-20,
    opacity:0,
  },
  enter: {
    x:0,
    opacity:1,
    transition: {
      x: { type: "spring", stiffness: 50},
      opacity: { duration: 1 }
    },
  }
};


export const MyCarousel = ({midia}) => {
  return (
    <Carousel>
      {midia.map( (currentProject,key) => {
      return(
      <Carousel.Item key={key} className="slideshow-project">
        <img className="d-block w-100 " src={currentProject.childImageSharp.fluid.src} alt={currentProject.title}/>
          <Carousel.Caption>
            <h3>{currentProject.title}</h3>
            <p>{currentProject.subtitle}</p>
          </Carousel.Caption>
      </Carousel.Item>)
      })}
   </Carousel>)
}

export const ProjectChild = ({project}) => {  
    return (
      <React.Fragment>
        <Divider text="NOSSO TRABALHO"/>
          <motion.div id="page-work-midia" variants={container}>  
            <div className="container-fluid h-100">
              <div className="row">
                <div className="col-md-12">
                  <motion.div className="container text-center mt-5 mb-5" id="midia-back" variants={variantsPortfolio}>
                    
                    <p>{`Acervo do projeto: ${project.title}`}</p>
                    <Link
                      to={`/projetos/${project.url}`}>
                        Voltar
                      </Link>
                      <MyCarousel midia={project.midia}/>
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.div>
      </React.Fragment>
    )
}

const Project = ({pageContext: project,...props}) => {

  return (    
    <motion.main
      variants={variants}
      initial="initial"
      animate="enter"
      exit="exit">
      <ProjectChild project={project} />
    </motion.main>
  );
}
 
export default Project